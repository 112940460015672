body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

.navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
}

.page-header {
  background-image: url('../../img/IHP_1762-banner-mobile.jpg');
  background-position: center top;

  @include media-breakpoint-up(sm) {
    background-image: url('../../img/IHP_1762-banner.jpg');
    background-position: 36% top;
  }
  @include media-breakpoint-up(md) {
    background-position: 28% top;
  }
  @include media-breakpoint-up(lg) {
    background-position: 50% top;
  }
}

.youtube {
  background-color: #000;
  margin-bottom: 30px;
  position: relative;
  padding-top: 56.25%;
  overflow: hidden;
  cursor: pointer;

  &:focus,
  &:hover {
    .play-button {
      background-color: #f50004;
    }
  }

  img {
    cursor: pointer;
    width: 100%;
    height: auto;
    position: absolute;
    top: -16.84%;
    left: 0;
    opacity: 0.7;
  }

  iframe {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }

  .play-button {
    width: 90px;
    height: 60px;
    background-color: #333;
    box-shadow: 0 0 30px rgba( 0,0,0,0.6 );
    cursor: pointer;
    z-index: 1;
    opacity: 0.8;
    border-radius: 6px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d( -50%, -50%, 0 );

    &:before {
      content: "";
      border-style: solid;
      border-width: 15px 0 15px 26.0px;
      border-color: transparent transparent transparent #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d( -50%, -50%, 0 );
    }
  }
}

.icon-shape {
  color: #fff;
  height: 3rem;
  width: 3rem;

  .ni {
    font-size: 1.5rem;
    line-height: 2;
  }
}
